import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'


const _hoisted_1 = { class: "header-content2" }
const _hoisted_2 = { class: "close-style2" }
const _hoisted_3 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_4 = { class: "group" }
const _hoisted_5 = { style: {"color":"white","line-height":"25px"} }
const _hoisted_6 = { class: "texto-alerta" }
const _hoisted_7 = { style: {"color":"#ffff","line-height":"23px","text-align":"justify","font-size":"1.4rem"} }
const _hoisted_8 = {
  class: "btn-content2",
  style: {"display":"flex","justify-content":"space-evenly"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopupReiniciarTerminal = _resolveComponent("PopupReiniciarTerminal")!

  return (_openBlock(), _createBlock(_component_PopupReiniciarTerminal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            style: {"cursor":"pointer","margin-top":"-1rem"},
            width: "25",
            height: "25",
            src: _imports_0,
            alt: "delete-sign--v1"
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("message.planta.f45")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.terminalSelected.terminals_nickname.toUpperCase()), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("message.planta.b17")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          class: "btn-baja",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.reiniciarTerminal && _ctx.reiniciarTerminal(...args))),
          type: "submit",
          id: "btn-cerrar-popup2"
        }, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.$t("message.planta.op30")), 1)
        ])
      ])
    ]),
    _: 1
  }))
}