import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/engranaje.png'


const _hoisted_1 = { class: "maxiem" }
const _hoisted_2 = { class: "perfil-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "table-wrapper" }
const _hoisted_6 = { class: "options-column" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "menu-dropdown"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loadingEvents)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Cargando datos..."))
        : (_ctx.events.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, " No hay datos disponibles para mostrar. "))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_DataTable, {
          value: _ctx.events,
          scrollable: "",
          scrollHeight: "400px",
          tableStyle: "min-width: 100%",
          class: "custom-datatable"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "siteId",
              header: "Siteid",
              style: { minWidth: '150px' }
            }),
            _createVNode(_component_Column, {
              field: "serialnumber",
              header: "Kit",
              style: { minWidth: '150px' }
            }),
            _createVNode(_component_Column, {
              field: "created_at",
              header: "Fecha y hora de solicitud",
              style: { minWidth: '180px' }
            }),
            _createVNode(_component_Column, {
              field: "event",
              header: "Solicitud (Acción sin api)",
              style: { minWidth: '200px' }
            }),
            _createVNode(_component_Column, {
              field: "user",
              header: "Solicitante",
              style: { minWidth: '150px' }
            }),
            _createVNode(_component_Column, {
              field: "status",
              header: "Estado",
              style: { minWidth: '100px' }
            }),
            _createVNode(_component_Column, {
              header: "Opciones",
              style: { minWidth: '100px' }
            }, {
              body: _withCtx((slotProps) => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("button", {
                    class: "p-button p-button-icon-only p-button-rounded p-button-info",
                    onClick: ($event: any) => (_ctx.showMenu(slotProps.data))
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("img", {
                      src: _imports_0,
                      width: "30",
                      height: "30",
                      alt: ""
                    }, null, -1)
                  ]), 8, _hoisted_7),
                  (_ctx.activeRow === slotProps.data.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", {
                            onClick: ($event: any) => (_ctx.finishEvent(slotProps.data))
                          }, _cache[1] || (_cache[1] = [
                            _createElementVNode("i", { class: "pi pi-check" }, null, -1),
                            _createTextVNode(" Finish ")
                          ]), 8, _hoisted_9)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ])
  ]))
}