<template>
  <div class="maxiem">
    <div class="perfil-content">

      <div v-if="loadingEvents">Cargando datos...</div>

      <div v-else-if="events.length === 0">
        No hay datos disponibles para mostrar.
      </div>

      <div class="table-wrapper">
        <DataTable :value="events" scrollable scrollHeight="400px" tableStyle="min-width: 100%"
          class="custom-datatable">
          <!-- <Column field="id" header="ID" :style="{ minWidth: '80px', width: '80px' }"></Column>
          <Column field="serialnumber" header="Serial Number" :style="{ minWidth: '150px' }"></Column>
          <Column field="siteId" header="Site ID" :style="{ minWidth: '120px' }"></Column>
          <Column field="detail" header="Detail" :style="{ minWidth: '200px' }"></Column>
          <Column field="event" header="Event" :style="{ minWidth: '150px' }"></Column>
          <Column field="platform" header="Platform" :style="{ minWidth: '120px' }"></Column>
          <Column field="status" header="Status" :style="{ minWidth: '100px' }"></Column>
          <Column field="user" header="User" :style="{ minWidth: '150px' }"></Column>
          <Column field="principal" header="Principal" :style="{ minWidth: '150px' }"></Column>
          <Column field="reference" header="Reference" :style="{ minWidth: '150px' }"></Column>
          <Column field="reference_id" header="Reference ID" :style="{ minWidth: '150px' }"></Column>
          <Column field="created_at" header="Created At" :style="{ minWidth: '180px' }"></Column> -->

          <Column field="siteId" header="Siteid" :style="{ minWidth: '150px' }"></Column>
          <Column field="serialnumber" header="Kit" :style="{ minWidth: '150px' }"></Column>
          <Column field="created_at" header="Fecha y hora de solicitud" :style="{ minWidth: '180px' }"></Column>
          <Column field="event" header="Solicitud (Acción sin api)" :style="{ minWidth: '200px' }"></Column>
          <Column field="user" header="Solicitante" :style="{ minWidth: '150px' }"></Column>
          <Column field="status" header="Estado" :style="{ minWidth: '100px' }"></Column>


          <Column header="Opciones" :style="{ minWidth: '100px' }">
            <template #body="slotProps">
              <div class="options-column">
                <button class="p-button p-button-icon-only p-button-rounded p-button-info"
                  @click="showMenu(slotProps.data)">
                  <img src="@/assets/engranaje.png" width="30" height="30" alt="" />
                </button>
                <div v-if="activeRow === slotProps.data.id" class="menu-dropdown">
                  <ul>
                    <li @click="finishEvent(slotProps.data)">
                      <i class="pi pi-check"></i> Finish
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </Column>

        </DataTable>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SplitButton from 'primevue/splitbutton';

import axios from "axios";
import store from "../../store";
import router from "@/router";

interface EventRow {
  id: number;
  event: string;
  platform: string;
  referenceId: string;
  status: number;
  updated_at: string;
  created_at: string;
}

export default defineComponent({
  name: "perfiltabla",
  components: {
    Column,
    DataTable,
    SplitButton
  },
  data() {
    return {
      mode: "perfil",
      activeRow: null as number | null,
    };
  },
  computed: {
    ...mapGetters(["events", "loadingEvents"]),

  },
  mounted() {
    this.GET_EVENTS()
      .then(() => {
        console.log("Eventos cargados:", this.events);
      })
      .catch((error) => {
        console.error("Error al cargar eventos:", error);
      });
  },
  methods: {

    ...mapActions(["GET_EVENTS"]),

    showMenu(rowData: EventRow) {
      this.activeRow = this.activeRow === rowData.id ? null : rowData.id;
    },

    async finishEvent(rowData: EventRow) {
      try {
        const requestBody = {
          id: rowData.id,
          created_at: rowData.created_at, 
        };

        const response = await axios.post(
          "https://96oarwjl9l.execute-api.us-east-1.amazonaws.com/Prod/starlinkEvent/finish",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Evento finalizado exitosamente:", response.data);

        if (response.data?.updated?.status === "FINALIZADO") {
          alert("El evento se finalizó correctamente.");
        } else {
          alert("El evento no pudo ser finalizado. Verifique los datos.");
        }

        this.GET_EVENTS(); 
      } catch (error) {
        console.error("Error al finalizar el evento:", error);
        alert("Hubo un error al finalizar el evento.");
      }
    }


  },
});
</script>
<style scoped>
.maxiem {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f4f4f4; */
}

.perfil-content {
  margin: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  max-width: 1200px;
  overflow: hidden;
  padding: 20px;
}

.loading-message,
.no-data-message {
  text-align: center;
  font-size: 1rem;
  color: #555;
}

.table-wrapper {
  overflow-x: auto;
}

.custom-datatable .p-datatable-thead>tr>th,
.custom-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
}

.custom-datatable .p-datatable-tbody>tr>td {
  overflow-wrap: anywhere;
}

.options-column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
  width: 150px;
}

.menu-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-dropdown li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu-dropdown li:hover {
  background-color: #f0f0f0;
}

.menu-dropdown i {
  font-size: 14px;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.create-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.create-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .perfil-content {
    width: 100%;
    margin: 10px;
  }

  .custom-datatable .p-datatable {
    font-size: 0.8rem;
  }

  .custom-datatable .p-datatable-thead>tr>th,
  .custom-datatable .p-datatable-tbody>tr>td {
    padding: 6px 8px;
  }
}
</style>
