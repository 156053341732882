<template>
    <PopupPerfilTabla>

        <div class="header-content2">
            <div class="close-style2">
                <img @click="closePopup" style="cursor: pointer; margin-top: -1rem" width="25" height="25"
                    src="@/assets/eliminar-50.png" alt="delete-sign--v1" />
            </div>


            <div>
                <h1 style="
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 3rem;
              color: white;
              font-weight: 100;
            ">
                    {{ $t("message.planta.f44") }}
                </h1>
            </div>
        </div>

        <div class="maxiem">
            <div class="perfil-content">

                <div v-if="loadingEvents">Cargando datos...</div>

                <div v-else-if="events.length === 0">
                    No hay datos disponibles para mostrar.
                </div>

                <div class="table-wrapper">
                    <DataTable :value="events" scrollable scrollHeight="400px" tableStyle="min-width: 100%"
                        class="custom-datatable">
                        <Column field="siteId" header="Siteid" :style="{ minWidth: '150px' }"></Column>
                        <Column field="serialnumber" header="Kit" :style="{ minWidth: '150px' }"></Column>
                        <Column field="created_at" header="Fecha de Solicitud" :style="{ minWidth: '180px' }">
                        </Column>
                        <Column field="updated_at" header="Fecha de Finalizado" :style="{ minWidth: '180px' }">
                            <template #body="slotProps">
                                <span v-if="slotProps.data.updated_at">
                                    {{ slotProps.data.updated_at }}
                                </span>
                                <span v-else>
                                    SIN FINALIZAR
                                </span>
                            </template>
                        </Column>
                        <Column field="event" header="Solicitud" :style="{ minWidth: '200px' }">
                        </Column>
                        <Column field="user" header="Solicitante" :style="{ minWidth: '150px' }"></Column>
                        <Column field="status" header="Estado" :style="{ minWidth: '100px' }"></Column>


                        <Column header="Opciones" :style="{ minWidth: '100px' }">
                            <template #body="slotProps">
                                <div class="options-column">
                                    <button v-if="permissions.some((evt: any) => evt.id === 170)" class="p-button p-button-icon-only p-button-rounded p-button-info"
                                        @click="showMenu(slotProps.data)">
                                        <img src="@/assets/engranaje.png" width="30" height="30" alt="" />
                                    </button>
                                    <div v-if="activeRow === slotProps.data.id" class="menu-dropdown">
                                        <ul>
                                            <li @click="finishEvent(slotProps.data)">
                                                <i class="pi pi-check"></i> Finish
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </template>
                        </Column>

                    </DataTable>
                </div>

            </div>
        </div>
    </PopupPerfilTabla>

</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SplitButton from 'primevue/splitbutton';

import axios from "axios";
import store from "../../../store";
import router from "@/router";
import PopupPerfilTabla from "@/components/planta-conte/PopupPerfilTabla.vue";

interface EventRow {
    id: number;
    event: string;
    platform: string;
    referenceId: string;
    status: number;
    updated_at: string;
    created_at: string;
}

export default defineComponent({
    name: "perfiltabla",
    components: {
        Column,
        DataTable,
        SplitButton,
        PopupPerfilTabla,
    },
    data() {
        return {
            mode: "perfil",
            activeRow: null as number | null,
        };
    },
    computed: {
        ...mapGetters(["events", "loadingEvents","permissions"]),

    },
    mounted() {
        this.GET_EVENTS()
            .then(() => {
                console.log("Eventos cargados:", this.events);
            })
            .catch((error) => {
                console.error("Error al cargar eventos:", error);
            });
    },
    methods: {
        closePopup() {
            this.$emit("close");
        },

        ...mapActions(["GET_EVENTS"]),

        showMenu(rowData: EventRow) {
            this.activeRow = this.activeRow === rowData.id ? null : rowData.id;
        },

        async finishEvent(rowData: EventRow) {
            try {
                const requestBody = {
                    id: rowData.id,
                    created_at: rowData.created_at,
                    updated_at: rowData.updated_at || null,
                };

                const response = await axios.post(
                    "https://96oarwjl9l.execute-api.us-east-1.amazonaws.com/Prod/starlinkEvent/finish",
                    requestBody,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                console.log("Evento finalizado exitosamente:", response.data);

                if (response.data?.updated?.status === "FINALIZADO") {
                    alert("El evento se finalizó correctamente.");
                } else {
                    alert("El evento no pudo ser finalizado. Verifique los datos.");
                }

                this.GET_EVENTS();
            } catch (error) {
                console.error("Error al finalizar el evento:", error);
                alert("Hubo un error al finalizar el evento.");
            }
        }


    },
});
</script>
<style scoped>
.maxiem {
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #f4f4f4; */
}

.perfil-content {
    margin: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 95%;
    max-width: 1500px;
    overflow: hidden;
    padding: 20px;
}

.loading-message,
.no-data-message {
    text-align: center;
    font-size: 1rem;
    color: #555;
}

.table-wrapper {
    overflow-x: auto;
}

.custom-datatable .p-datatable-thead>tr>th,
.custom-datatable .p-datatable-tbody>tr>td {
    text-align: left;
    padding: 8px 12px;
    border-bottom: 1px solid #eee;
}

.custom-datatable .p-datatable-tbody>tr>td {
    overflow-wrap: anywhere;
}

.options-column {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 10;
    width: 150px;
}

.menu-dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu-dropdown li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.menu-dropdown li:hover {
    background-color: #f0f0f0;
}

.menu-dropdown i {
    font-size: 14px;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.create-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.create-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .perfil-content {
        width: 100%;
        margin: 10px;
    }

    .custom-datatable .p-datatable {
        font-size: 0.8rem;
    }

    .custom-datatable .p-datatable-thead>tr>th,
    .custom-datatable .p-datatable-tbody>tr>td {
        padding: 6px 8px;
    }
}
</style>