import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/eliminar-50.png'
import _imports_1 from '@/assets/engranaje.png'


const _hoisted_1 = { class: "header-content2" }
const _hoisted_2 = { class: "close-style2" }
const _hoisted_3 = { style: {"text-align":"center","margin-top":"1rem","margin-bottom":"3rem","color":"white","font-weight":"100"} }
const _hoisted_4 = { class: "maxiem" }
const _hoisted_5 = { class: "perfil-content" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "table-wrapper" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "options-column" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 1,
  class: "menu-dropdown"
}
const _hoisted_14 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PopupPerfilTabla = _resolveComponent("PopupPerfilTabla")!

  return (_openBlock(), _createBlock(_component_PopupPerfilTabla, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
            style: {"cursor":"pointer","margin-top":"-1rem"},
            width: "25",
            height: "25",
            src: _imports_0,
            alt: "delete-sign--v1"
          })
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("message.planta.f44")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.loadingEvents)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Cargando datos..."))
            : (_ctx.events.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No hay datos disponibles para mostrar. "))
              : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_DataTable, {
              value: _ctx.events,
              scrollable: "",
              scrollHeight: "400px",
              tableStyle: "min-width: 100%",
              class: "custom-datatable"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "siteId",
                  header: "Siteid",
                  style: { minWidth: '150px' }
                }),
                _createVNode(_component_Column, {
                  field: "serialnumber",
                  header: "Kit",
                  style: { minWidth: '150px' }
                }),
                _createVNode(_component_Column, {
                  field: "created_at",
                  header: "Fecha de Solicitud",
                  style: { minWidth: '180px' }
                }),
                _createVNode(_component_Column, {
                  field: "updated_at",
                  header: "Fecha de Finalizado",
                  style: { minWidth: '180px' }
                }, {
                  body: _withCtx((slotProps) => [
                    (slotProps.data.updated_at)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(slotProps.data.updated_at), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_10, " SIN FINALIZAR "))
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "event",
                  header: "Solicitud",
                  style: { minWidth: '200px' }
                }),
                _createVNode(_component_Column, {
                  field: "user",
                  header: "Solicitante",
                  style: { minWidth: '150px' }
                }),
                _createVNode(_component_Column, {
                  field: "status",
                  header: "Estado",
                  style: { minWidth: '100px' }
                }),
                _createVNode(_component_Column, {
                  header: "Opciones",
                  style: { minWidth: '100px' }
                }, {
                  body: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_11, [
                      (_ctx.permissions.some((evt) => evt.id === 170))
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "p-button p-button-icon-only p-button-rounded p-button-info",
                            onClick: ($event: any) => (_ctx.showMenu(slotProps.data))
                          }, _cache[1] || (_cache[1] = [
                            _createElementVNode("img", {
                              src: _imports_1,
                              width: "30",
                              height: "30",
                              alt: ""
                            }, null, -1)
                          ]), 8, _hoisted_12))
                        : _createCommentVNode("", true),
                      (_ctx.activeRow === slotProps.data.id)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("ul", null, [
                              _createElementVNode("li", {
                                onClick: ($event: any) => (_ctx.finishEvent(slotProps.data))
                              }, _cache[2] || (_cache[2] = [
                                _createElementVNode("i", { class: "pi pi-check" }, null, -1),
                                _createTextVNode(" Finish ")
                              ]), 8, _hoisted_14)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}